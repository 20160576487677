<template>
  <div v-if="receiver">
    <h1 class="flex items-center">
      <img v-if="receiver.has_profile_picture" :src="`${storageUrl}/${receiver.id}/profile-picture.jpg`" loading="lazy" class="w-8 h-8 rounded-full mr-3 bg-gray-100">      
      {{receiver.username}}
      <span class="bg-yellow-100 text-yellow-700 text-base font-semibold p-1 pl-2 pr-2 ml-5 rounded-lg" v-if="receiver.state === 'RECEIVER_ADDED'">
        Queued for scraping
      </span>
      <span class="bg-blue-100 text-blue-700 text-base font-semibold p-1 pl-2 pr-2 ml-5 rounded-lg" v-else-if="receiver.state === 'RECEIVER_SCRAPED'">
        Queued for delivery
      </span>
      <span class="bg-red-100 text-red-700 text-base font-semibold p-1 pl-2 pr-2 ml-5 rounded-lg" v-else-if="receiver.state === 'RECEIVER_NOT_FOUND'">
        Profile not found
      </span>
      <span class="bg-green-100 text-green-700 text-base font-semibold p-1 pl-2 pr-2 ml-5 rounded-lg" v-else-if="receiver.state === 'MESSAGE_DELIVERED'">
        Message delivered
      </span>
      <span class="bg-red-100 text-red-700 text-base font-semibold p-1 pl-2 pr-2 ml-5 rounded-lg" v-else-if="receiver.state === 'MESSAGE_RETRYING' || receiver.state === 'MESSAGE_FAILED'">
        Message not delivered
      </span>
    </h1>
    <div class="flex flex-wrap">
      <!-- Info column -->
      <div class="lg:w-3/5 leading-loose p-6 bg-white shadow-md">
        <div class="flex flex-wrap">
          <div class="lg:w-2/4 pr-5">
            <label class="block font-bold text-gray-600 pb-2">
              Username:
            </label>
            <p class="w-full h-10 px-3 py-1 text-gray-700 bg-gray-200 rounded">
              {{receiver.username}}
            </p>
          </div>
          <div class="lg:w-2/4">
            <label class="block font-bold text-gray-600 pb-2">
              Full Name:
            </label>
            <input class="w-full h-10 px-3 py-1 text-gray-700 bg-gray-200 rounded" type="text" v-model="receiver.fullname">
          </div>
        </div>
        <div class="flex flex-wrap mt-4">
          <div class="lg:w-2/4 pr-5">
            <label class="block font-bold text-gray-600 pb-2">
              Created at:
            </label>
            <p class="w-full h-10 px-3 py-1 text-gray-700 bg-gray-200 rounded">
              {{receiver.created_at | formatDate('dd/MM/yyyy HH:mm')}}
            </p>
          </div>
          <div class="lg:w-2/4">
            <label class="block font-bold text-gray-600 pb-2">
              Updated at:
            </label>
            <p class="w-full h-10 px-3 py-1 text-gray-700 bg-gray-200 rounded">
              {{receiver.updated_at | formatDate('dd/MM/yyyy HH:mm')}}
            </p>
          </div>
        </div>
        <div class="flex flex-wrap pt-4" v-if="receiver.category_name && receiver.is_business_profile">
          <div class="w-full">
            <label class="block font-bold text-gray-600 pb-2">
              Business Category:
            </label>
            <p class="w-full h-10 px-3 py-1 text-gray-700 bg-gray-200 rounded">
              {{receiver.category_name}} {{receiver.category_enum ? `(${receiver.category_enum})`: null}}
            </p>
          </div>
        </div>
        <div class="flex space-x-6 pt-4">
          <div class="lg:w-1/3 h-10">
            <label class="block font-bold text-gray-600">
              Posts:
            </label>
            <p class="w-full h-10 text-center p-1 mt-2 text-gray-700 bg-gray-200 rounded">
              {{receiver.posts_count ? receiver.posts_count.toLocaleString('da-DK') : 'N/A'}}
            </p>
          </div>
          <div class="lg:w-1/3 h-10">
            <label class="block font-bold text-gray-600">
              Followers:
            </label>
            <p class="w-full h-10 text-center p-1 mt-2 text-gray-700 bg-gray-200 rounded">
              {{receiver.followers_count ? receiver.followers_count.toLocaleString('da-DK') : 'N/A'}}
            </p>
          </div>
          <div class="lg:w-1/3">
            <label class="block font-bold text-gray-600">
              Following:
            </label>
            <p class="w-full h-10 text-center p-1 mt-2 text-gray-700 bg-gray-200 rounded">
              {{receiver.following_count ? receiver.following_count.toLocaleString('da-DK') : 'N/A'}}
            </p>
          </div>
        </div>
        <div class="flex space-x-6 pt-4">
          <div class="lg:w-1/3">
            <label class="block font-bold text-gray-600">
              Private profile:
            </label>
            <p class="w-full h-10 text-center p-1 mt-2 text-gray-700 bg-gray-200 rounded text-center p-1 mt-2">
              {{receiver.is_private_profile ? 'Yes' : 'No'}}
            </p>
          </div>
          <div class="lg:w-1/3">
            <label class="block font-bold text-gray-600">
              Verified profile:
            </label>
            <p class="w-full h-10 p-1 mt-2 text-gray-700 bg-gray-200 text-center rounded">
              {{receiver.is_verified_profile ? 'Yes' : 'No'}}
            </p>
          </div>
          <div class="lg:w-1/3">
            <label class="block font-bold text-gray-600">
              Business profile:
            </label>
            <p class="w-full h-10 p-1 mt-2 text-gray-700 bg-gray-200 text-center rounded">
              {{receiver.is_business_profile ? 'Yes' : 'No'}}
            </p>
          </div>
        </div>
        <!-- Action buttons -->
        <div>
          <div class="grid grid-cols-2 mt-6">
            <div class="justify-self-start">
              <button class="pt-1 pr-3 pb-1 pl-3 text-white font-light tracking-wider bg-gray-500 rounded" @click="updateReceiver">
                Save changes
              </button>
            </div>
            <div class="justify-self-end">
              <button class="pt-1 pr-3 pb-1 pl-3 mr-5 text-white font-light tracking-wider bg-yellow-500 rounded" @click="resetReceiver">
                Reset
              </button>
              <button class="pt-1 pr-3 pb-1 pl-3 text-white font-light tracking-wider bg-red-500 rounded" @click="deleteReceiver">
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- Log and files -->
      <div class="lg:w-2/5 leading-loose p-6 bg-white shadow-md border-l-1 border-gray-800">
        <h2 class="text-gray-500">Recipient Logs</h2>
        <ul>
          <li v-for="log in receiver.logs" :key="log.id" class="grid grid-cols-2">
            <div class="place-self-start font-semibold pl-1">
              {{log.state | translateState}}
            </div>
            <div class="place-self-end text-gray-400">
              ({{log.created_at | formatDate('dd/MM HH:mm')}})
            </div>
          </li>
        </ul>
        <h2 class="text-gray-500 mt-5">Storage Explorer</h2>
        <ul>
          <li v-for="file in receiver.blob_storage_files" :key="file.id" class="grid grid-cols-2">
            <a :href="`${storageUrl}/${receiver.id}/${file.file_name}`" target="_blank" class="place-self-start font-semibold pl-1">
              {{file.content_type | translateFileType}}
            </a>
            <div class="place-self-end text-gray-400">
              ({{file.created_at | formatDate('dd/MM HH:mm')}})
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  props: ['projectId', 'receiverId'],

  data () {
    return {
      receiver: null,
      storageUrl: `${process.env.VUE_APP_API_URL}/projects/recipient/file`,
    }
  },

  methods: {
    resetReceiver() 
    {
      if (confirm('Are you sure you want to reset this recipient?\n\nAll associated data will be removed and needs to be scraped again.'))
      {
        axios
          .put(`${process.env.VUE_APP_API_URL}/projects/recipients/${this.projectId}/reset`, {
            ids: [parseInt(this.receiverId)]
          })
          .then(() => {
            this.$router.push(`/projects/${this.projectId}/recipients/${this.receiverId}`);
          })
          .catch(error => {
            console.log(error)
          });
      }
    },
    updateReceiver() 
    {
      axios
        .put(`${process.env.VUE_APP_API_URL}/projects/recipient/${this.receiverId}`, {
          fullname: this.receiver.fullname,
          username: this.receiver.username,
        })
        .then(() => {
          this.$router.push(`/projects/${this.projectId}/recipients`);
        })
        .catch(error => {
          console.log(error)
        });
    },
    deleteReceiver() 
    {
      if (confirm('Are you sure you want to delete this recipient?'))
      {
        axios
          .delete(`${process.env.VUE_APP_API_URL}/projects/recipients/${this.projectId}`, {
            data: {
              ids: [parseInt(this.receiverId)],
            }
          })
          .then(() => {
            this.$router.push(`/projects/${this.projectId}/recipients`);
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
  },

  mounted () {
    axios.get(`${process.env.VUE_APP_API_URL}/projects/recipient/${this.receiverId}`)
      .then(response => {
        this.receiver = response.data
      })
      .catch(error => {
        console.log(error)
      })
  },

  filters: {
    translateState: function (value) {
      switch (value) {
        case 'RECEIVER_ADDED': 
          return '👤 Recipient added';
        case 'RECEIVER_DELETED':
          return '❌ Recipient deleted';
        case 'RECEIVER_SCRAPED':
          return '💾 Recipient scraped';
        case 'RECEIVER_NOT_FOUND': 
          return '❌ Recipient not found';
        case 'MESSAGE_DELIVERED':
          return '📬 Message delivered';
        case 'MESSAGE_RETRYING':
          return '❌ Retrying message send';
        case 'MESSAGE_FAILED':
          return '😬 Unable to send message';
        default:
          return `Unknown state (${value})`
      }
    },
    translateFileType: function(value)
    {
      switch (value) {
        case 'image/jpeg':
          return '🖼 Profile picture';
        case 'video/webm':
          return '🎥 Message sending';
        default:
          return `Unknown type (${value})`;
      }
    }
  },
}
</script>